import { toLower, trimStart } from "lodash";
import { removeWebPrefix } from "shared";
import { flowlib } from "api";
import { locationIconMap } from "./location-icons";
import { locations } from "../risks/shared/locations";

export const EventTypesMap: Record<string, string> = {
  app_access: "Opened file",
  attachment_add: "Attached file",
  attachment_save: "Saved email attachment",
  attachment_send: "Sent",
  send: "Sent",
  sent_email: "Sent",
  clipboard_copypaste: "Copy/Pasted",
  copy: "Copied file",
  create: "Created file",
  create_edit: "Created file",
  download: "Downloaded file",
  edit: "Edited file",
  move: "Moved file",
  receive: "Received file",
  received_email: "Received file",
  rename: "Renamed file",
  rename_copy: "Copied file",
  sent_to_printer: "Printed",
  upload: "Uploaded file",
  zip: "Compressed file",
  unzip: "Extracted file",
  share: "Shared file",
  dlp_scan: "Scanned file",
  save_as: "Exported file",
};
// Derived data and post transformation
export type EventTypes = keyof typeof EventTypesMap;

export function formatEventType(str: string, location: string | undefined) {
  // todo handle rename
  if (EventTypesMap[str]) {
    var baseEventTypeFormat = toLower(EventTypesMap[str]);
    if (str === "sent_email") {
      if (location === "email_body") {
        baseEventTypeFormat = baseEventTypeFormat + " email";
      } else {
        baseEventTypeFormat = baseEventTypeFormat + " file";
      }
    }
    return baseEventTypeFormat;
  }
  throw new Error("unknown event type");
}

function formatSharedWith(users: string[] | null | undefined) {
  if (users && users !== undefined) {
    return users.join(", ");
  }
  return undefined;
}

export function getLabel(
  data: Pick<
    flowlib.V2Node,
    | "location"
    | "hostname"
    | "email_account"
    | "email_subject"
    | "domain"
    | "url"
    | "cloud_provider"
    | "location_outline"
    | "app_name"
    | "app_description"
    | "cloud_shared_with"
  >,
  flowStart: Boolean = false
) {
  switch (toLower(data.location)) {
    case locations.endpoint.value:
      return data.hostname;
    case locations.mail.value:
      return data.email_account;
    case locations.email_body.value:
      if (flowStart) {
        return data.email_account;
      }
      return data.email_subject;
    case locations.website.value:
      return trimStart(data.domain, ".") || removeWebPrefix(data.url!);
    case locations.share.value:
      return data.hostname;
    case locations.cloud_storage.value:
      return (
        formatSharedWith(data.cloud_shared_with) ||
        trimStart(data.domain, ".") ||
        data.cloud_provider
      );
    case locations.cloud_apps.value:
      return data.location_outline;
    case locations.endpoint_apps.value:
      return data.app_description || data.app_name;
    default:
      return data.location_outline || data.location;
  }
}

export function getLocationIcon(location: string) {
  return locationIconMap[toLower(location)];
}

function getDeduplicatedOptions() {
  const res = Object.entries(EventTypesMap).reduce((acc, [key, value]) => {
    if (acc[value]) {
      acc[value].push(key as any);
    } else {
      acc[value] = [key as any];
    }
    return acc;
  }, {} as Record<string, EventTypes[]>);
  return res;
}
// contains options which are either strings or array of strings, if it's array of strings they both represent same option
// and both have same weight
const eventsOptions = getDeduplicatedOptions();

export function getDuplicatedEvents(k: EventTypes) {
  return eventsOptions[EventTypesMap[k]];
}

export function getUniqEvents<T>(options: T[], iterBy: (v: T) => EventTypes) {
  return options.filter((el) => {
    const k = iterBy(el);
    const keys = getDuplicatedEvents(k);
    return keys?.[0] === k;
  }, []);
}

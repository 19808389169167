import { compareByProp } from "libs/data-utils";
import { LocationCategoryValues } from "api/gen";
import { SensorType } from "modules/risks/shared/constants";
import { getSensorNames } from "modules/risks/shared";
import { getIcon } from "./location-icons";
import { locations } from "../risks/shared/locations";
import { remapCategories } from "./location-categories-remap";

const topLevelLocations = [
  "LocationCategoryCloudApps",
  "LocationCategoryCloudStorage",
  "LocationCategoryEndpoint",
  "LocationCategoryEndpointApps",
  "LocationCategoryMail",
  //"LocationCategoryMailBody", // temporary disabled for 24.05
  "LocationCategoryPrinter",
  "LocationCategoryRemovableMedia",
  "LocationCategoryShare",
  "LocationCategoryWebsite",
] as const;

type TopLevelLocations = (typeof topLevelLocations)[number];

const topLevelLocationsMeta: Record<
  TopLevelLocations,
  { label: string; icon: JSX.Element; color: string }
> = {
  LocationCategoryCloudApps: {
    label: locations.cloud_apps.label,
    icon: getIcon("cloud_apps"),
    color: locations.cloud_apps.color,
  },
  LocationCategoryCloudStorage: {
    label: locations.cloud_storage.label,
    icon: getIcon("cloud_storage"),
    color: locations.cloud_storage.color,
  },
  LocationCategoryEndpoint: {
    label: locations.endpoint.label,
    icon: getIcon("endpoint"),
    color: locations.endpoint.color,
  },
  LocationCategoryEndpointApps: {
    label: locations.endpoint_apps.label,
    icon: getIcon("endpoint_apps"),
    color: locations.endpoint_apps.color,
  },
  LocationCategoryMail: {
    label: locations.mail.label,
    icon: getIcon("mail"),
    color: locations.mail.color,
  },
  /*LocationCategoryMailBody: {
    label: locations.email_body.label,
    icon: getIcon("email_body"),
    color: locations.mail.color,
  },*/ //Temporary disabled for 24.05
  LocationCategoryPrinter: {
    label: locations.printer.label,
    icon: getIcon("printer"),
    color: locations.printer.color,
  },
  LocationCategoryRemovableMedia: {
    label: locations.removable_media.label,
    icon: getIcon("removable_media"),
    color: locations.removable_media.color,
  },
  LocationCategoryShare: {
    label: locations.share.label,
    icon: getIcon("share"),
    color: locations.share.color,
  },
  LocationCategoryWebsite: {
    label: locations.website.label,
    icon: getIcon("website"),
    color: locations.website.color,
  },
};

const websiteOptions = (
  Object.keys(LocationCategoryValues) as Array<keyof typeof LocationCategoryValues>
)
  .filter((el) => el.startsWith("LocationCategoryWebsite") && el !== "LocationCategoryWebsite")
  .map((el) => ({
    value: LocationCategoryValues[el],
    label: remapCategories(LocationCategoryValues[el].replace("website.", "")),
    icon: topLevelLocationsMeta.LocationCategoryWebsite.icon,
  }))
  .sort(compareByProp("label"));

export const locationCategoryOptions = topLevelLocations
  .map((el) => ({
    value: LocationCategoryValues[el],
    label: topLevelLocationsMeta[el].label,
    icon: topLevelLocationsMeta[el].icon,
    children: el === "LocationCategoryWebsite" ? websiteOptions : undefined,
    childrenKey: el === "LocationCategoryWebsite" ? "LocationCategoryWebsite" : undefined,
  }))
  .sort(compareByProp("label"));

export const sensorTypesOptions = Object.values(SensorType).map((type) => ({
  value: type,
  label: type,
  children: getSensorNames([type]).map((name) => {
    const nameNoType = name.replace(type, "").replace("Sensor", "");
    return {
      value: name,
      label: nameNoType ? addSpacesBeforeUppercaseLetters(nameNoType) : "Browser Extension",
    };
  }),
  childrenKey: type,
}));

function addSpacesBeforeUppercaseLetters(str: string): string {
  return str.replace(/([A-Z])/g, " $1");
}
